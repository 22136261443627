import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from "ng-inline-svg-2";
import { UiCTableModule } from 'src/app/shared/components/ui-c-table/ui-c-table.module';
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { SharedModule } from "src/app/shared/shared.module";
import { UiCInputModule } from "src/app/shared/components/ui-c-input/ui-c-input.module";
import { MatDialogModule } from "@angular/material/dialog";
import { ModalTokenComponent } from './modal-token/modal-token.component';
import { ModalCommissionedOperationComponent } from './modal-commissioned-operation/modal-commissioned-operation.component';
import { ModalChargedAccountComponent } from './modal-charged-account/modal-charged-account.component';
import { MatTableModule } from '@angular/material/table';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
  imports: [
    CommonModule,
    InlineSVGModule,
    UiCTableModule,
    MatSlideToggleModule,
    SharedModule,
    UiCInputModule,
    MatDialogModule,
    MatTableModule,
    MatRadioModule,
  ],
  declarations: [
    ModalTokenComponent,
    ModalCommissionedOperationComponent,
    ModalChargedAccountComponent
  ]
})
export class ModalsModule { }
