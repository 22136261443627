import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { OperationModel } from 'src/app/core/proxy/enum/operation-model';

export interface IOperationSelected {
    uuid: string;
    uuidOperation: string;
    name: string;
    value: string;
}

export interface ModalCommissionedOperationComponentProps {
    typeOperations: OperationModel[];
    operationSelected?: IOperationSelected;
}

@Component({
    selector: 'modal-commissioned-operation',
    templateUrl: './modal-commissioned-operation.component.html',
    styleUrls: ['./modal-commissioned-operation.component.scss']
})
export class ModalCommissionedOperationComponent implements OnInit {

    form: FormGroup;

    @Output() onSubmit = new EventEmitter<IOperationSelected>();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ModalCommissionedOperationComponentProps,
        private formBuilder: FormBuilder,
        private dialogRef: MatDialogRef<ModalCommissionedOperationComponent>,
    ) { }

    ngOnInit(): void {
        this.buildForm();
    }

    buildForm() {
        this.form = this.formBuilder.group({
            uuid: new FormControl(this.data.operationSelected?.uuid),
            uuidOperation: new FormControl(this.data.operationSelected?.uuidOperation),
            value: new FormControl(this.data.operationSelected?.value),
        })
    }

    handleClickCancel(): void {
        this.dialogRef.close();
    }
   
    handleClickSubmit(): void {
        const {uuid, uuidOperation, value} = this.form.value;
        const typeOperationSelected = this.data.typeOperations.filter(typeOperation => typeOperation.uuid === uuidOperation)[0];

        const operationSelected: IOperationSelected = {
            uuid: uuid,
            uuidOperation: typeOperationSelected?.uuid,
            name: typeOperationSelected?.description,
            value: value
        }
        this.onSubmit.emit(operationSelected);
    }
}
