
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from "@angular/material/dialog";
import { UserInfoProfile } from 'src/app/core/_base/_auth/dto/user-info-profile';
import { UserInfoService } from 'src/app/core/_base/_auth/user-info.service';
import { AccountPaymentGroupDto } from 'src/app/core/proxy/dto/account-payment-group.dto';
import { SpeViewNBackofficeClientProxy } from 'src/app/core/proxy/view/spe-view-n-backoffice-client.proxy';
import { AbstractPage } from '../../pages/manager/abstract-page';


@Component({
    selector: 'modal-charged-account',
    templateUrl: './modal-charged-account.component.html',
    styleUrls: ['./modal-charged-account.component.scss']
})
export class ModalChargedAccountComponent extends AbstractPage implements OnInit {

    userInfo: UserInfoProfile;
    form: FormGroup;
    fetchingData: boolean = false;

    accounts: AccountPaymentGroupDto[];
    displayedColumns: string[] = ['select', 'name', 'document', 'accountNumber'];
    selectedAccount: AccountPaymentGroupDto | null = null;;

    @Output() onSubmit = new EventEmitter<AccountPaymentGroupDto | null>();

    constructor(
        private userInfoService: UserInfoService,
        private speViewCommissionedPartner: SpeViewNBackofficeClientProxy,
        private formBuilder: FormBuilder,
        private dialogRef: MatDialogRef<ModalChargedAccountComponent>,
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.userInfo = await this.userInfoService.getInfoProfile() as UserInfoProfile;
        this.buildForm();
    }

    buildForm() {
        this.form = this.formBuilder.group({
            textSearch: new FormControl(''),
        })
    }

    handleClickSearch(): void {
        const textSearch = this.form.get('textSearch')?.value;
        this.fetchingData = true;
        this.selectedAccount = null;

        if (textSearch) {
            this.speViewCommissionedPartner.findAccountsOfAccountGroupByKeyword(this.userInfo.uuidAccount, textSearch)
                .subscribe({
                    next: chargebleAccounts => {
                        this.fetchingData = false;
                        this.accounts = chargebleAccounts;
                    },
                    error: error => {
                        this.fetchingData = false;
                    }
                })
        }
    }

    selectItem(item: AccountPaymentGroupDto): void {
        this.selectedAccount = item;
    }

    handleClickCancel(): void {
        this.dialogRef.close();
    }

    handleClickSubmit(): void {
        this.onSubmit.emit(this.selectedAccount);
    }
}
