import {NgModule} from '@angular/core';
import {ThemesRoutingModule} from './themes-routing.module';
import {ThemesComponent} from './themes.component';
import {CommonModule} from '@angular/common';
import { ModalsModule } from './gamma/modals/modals.module';

@NgModule({
  declarations: [ThemesComponent],
  imports: [
    CommonModule,
    ModalsModule,
    ThemesRoutingModule,
  ],
})
export class ThemesModule { }