import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../_base/_auth/api.service';
import { CommissionedGroupModel } from '../dto/commissioned-group-model';
import { OperationModel } from '../enum/operation-model';
import { AccountPaymentGroupDto } from '../dto/account-payment-group.dto';

const SPE_VIEW_N_BACKOFFICE_CLIENT = '/spe-view-n-backoffice-client';

@Injectable(
  { providedIn: 'root' }
)
export class SpeViewNBackofficeClientProxy {

  constructor(private api: ApiService) {
  }

  findCommissionedGroupsByAccountPayment(uuidAccountPayment: string): Observable<CommissionedGroupModel[]> {
    const uri = `${SPE_VIEW_N_BACKOFFICE_CLIENT}/v1/commissioned-group/of-account-payment/${uuidAccountPayment}`;
    return this.api.get(uri);
  }

  findCommissionedGroupByUuid(uuidCommissionedGroup: string): Observable<CommissionedGroupModel> {
    const uri = `${SPE_VIEW_N_BACKOFFICE_CLIENT}/v1/commissioned-group/of-uuid/${uuidCommissionedGroup}`;
    return this.api.get(uri);
  }

  findDeductionsForAccountPayment(uuidAccountPayment: string): Observable<OperationModel[]> {
    const uri = `${SPE_VIEW_N_BACKOFFICE_CLIENT}/v1/commissioned-group/deductions/${uuidAccountPayment}`;
    return this.api.get(uri);
  }

  findAccountsOfAccountGroupByKeyword(uuidAccountPayment: string, keyword: string): Observable<AccountPaymentGroupDto[]> {
    const uri = `${SPE_VIEW_N_BACKOFFICE_CLIENT}/v1/commissioned-group/account-payments/of-group/${uuidAccountPayment}/keyword/${keyword}`;
    return this.api.get(uri);
  }

}
