import {RouterModule, Routes} from '@angular/router';

import {NgModule} from '@angular/core';
import {ThemesComponent} from "./themes.component";
import {CanAuthenticationGuard} from "../../core/_base/_auth/guard/can-authentication-guard";

let routes: Routes = [
  {
    path: '',
    component: ThemesComponent,
    children: [
      {
        path: '',
        redirectTo: 'app',
        pathMatch: 'full',
      },
      {
        path: 'app',
        loadChildren: () => import('./gamma/pages/manager/manager.module').then((m) => m.ManagerModule),
        canActivate: [CanAuthenticationGuard],
      },

    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ThemesRoutingModule {}
