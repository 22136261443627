<div class="custom-modal modal-commissioned-operation">

    <div class="modal-content">

        <div class="modal-title">
            <h1>Adicionar/Alterar Operação</h1>
        </div>

        <form [formGroup]="form">

            <div class="form-group">
                <label class="form-label fs-6 fw-bolder text-dark mt-3">Operação:</label>
                <select class="form-select form-select-solid form-select-lg text-dark"
                    formControlName="uuidOperation"
                    [attr.disabled]="data.operationSelected"
                    >
                    <option *ngFor="let operation of data.typeOperations" [value]="operation.uuid">
                        {{ operation.description }}
                    </option>
                </select>
            </div>

            <div class="form-group">
                <app-ui-c-input
                    [form]="form"
                    [formName]="'value'"
                    [name]="'Percentual:'"
                    [isMoney]="false"
                    [isPercent]="true"
                    [placeholder]="'0.00%'"></app-ui-c-input>
            </div>

        </form>

    </div>

    <div class="modal-buttons">
        <button type="button" class="btn btn-danger" (click)="handleClickCancel()">Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="handleClickSubmit()">Confirmar</button>
    </div>
</div>