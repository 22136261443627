<div class="token-modal">

    <div>
        <p>Digite o token enviado por SMS ou gerado no App.</p><br />
    
        <div class="form-group box-token">
            <label for="input-liq-token"><strong>Token de Autorização:</strong></label>
            <input
                [formControl]="tokenValue"
                id="input-liq-token"
                placeholder="Digite o token"
                class="form-control" />

            <p (click)="handleClickResendToken()">Reenviar token</p>
        </div>
    </div>

    <div class="buttons">
        <button type="button" class="btn btn-danger" (click)="handleClickCancel()">Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="handleClickSubmit()">Confirmar</button>
    </div>
</div>