import { Injectable } from '@angular/core';
import { ApiService } from '../../_base/_auth/api.service';
import { Observable } from 'rxjs';
import { AccessAccountInterface } from '../../_base/_auth/dto/access-account.interface';

const SPE_VIEW_N_ACCOUNT = '/spe-view-n-account';

@Injectable(
  { providedIn: 'root' }
)
export class SpeViewNAccountProxy {

  constructor(private api: ApiService) {
  }

  getAccounts(): Observable<Array<AccessAccountInterface>> {
    const uri = `${SPE_VIEW_N_ACCOUNT}/v1/access/accounts`;
    return this.api.get(uri);
  }

}
