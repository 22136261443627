import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { ExtraAttribute, UserInfoProfile } from './dto/user-info-profile';
import { KeycloakProfileCustom } from './dto/keycloak-profile-custom';
import { RoleEnum } from './enum/role-type.enum';
import { SpeViewNAccountProxy } from '../../proxy/view/spe-view-n-account.proxy';
import { SpeNAccountsProxy } from '../../proxy/spe/spe-n-accounts.proxy';
import { Observable } from 'rxjs';


@Injectable(
  {
    providedIn: "root"
  }
)
export class UserInfoService {

  constructor(
    protected keycloakService: KeycloakService,
    private speViewNAccountProxy: SpeViewNAccountProxy,
    private speNAccountsProxy: SpeNAccountsProxy,
  ) { }

  setUuidAccount(uuid: number, uuidWl: string) {
    localStorage.setItem('uuid_account_' + uuidWl, String(uuid));
  }

  changeAccount(userId: string): Observable<any> {
    return this.speNAccountsProxy.changeAccount(userId)
  }

  async getInfoProfile(): Promise<UserInfoProfile | undefined> {
    try {
      let userProfile: UserInfoProfile = new UserInfoProfile();
      const userDetails: KeycloakProfileCustom = await this.keycloakService.loadUserProfile(true);
      const uuidAccount = this.cleanAttribute(this.searchAttribute(userDetails, 'uuidAccount'));
      const accounts = (await this.speViewNAccountProxy.getAccounts().toPromise());

      if (accounts) {
        for (const account of accounts) {

          if (!uuidAccount) {
            this.changeAccount(account.userId);
          }

          if (!uuidAccount || uuidAccount === account.uuidAccount) {

            userProfile.email = userDetails.email as string;
            userProfile.isEC = false;
            userProfile.uuidWhiteLabel = account.uuidWl;
            userProfile.uuidAccount = uuidAccount;
            userProfile.nameComplete = account.accountName;
            userProfile.nameFirst = account.accountName.split(' ')[0];
            userProfile.nameInitials = account.nameInitials;
            userProfile.codeBank = account.codeBank;
            userProfile.nameBank = account.nameBank;
            userProfile.agencyBank = account.agencyBank;
            userProfile.numberAccountBank = account.numberAccountBank;
            userProfile.extraAttributes = this.preparerExtraAttribute(userDetails);
            userProfile.rolesMenu = account.rolesMenu;
            if (userProfile.listAccounts) {
              userProfile.listAccounts.push(account);
            } else {
              userProfile.listAccounts = [account];
            }
          } else {
            if (userProfile.listAccounts) {
              userProfile.listAccounts.push(account);
            } else {
              userProfile.listAccounts = [account];
            }
          }
        }
      }

      return userProfile;
    } catch (e) {
      console.log('Failed to load user details', e);
      this.logoutForTimeOut();
    }
  }

  searchAttribute(keycloakProfile: KeycloakProfileCustom, attributeSearch: string) {
    if (keycloakProfile.attributes) {
      const keyNames = Object.keys(keycloakProfile.attributes);
      for (const attribute of keyNames) {
        if (attribute === attributeSearch) {
          return this.captureValue(keycloakProfile.attributes[attribute]);
        }
      }
    }
    return null;
  }

  private cleanAttribute(attribute: any) {
    if (attribute) {
      // @ts-ignore
      return (attribute + '').replace('"', '').replace('"', '');
    }
    return attribute;
  }

  private preparerExtraAttribute(keycloakProfile: KeycloakProfileCustom): ExtraAttribute[] {
    const extras: Array<ExtraAttribute> = new Array<ExtraAttribute>();

    if (keycloakProfile.attributes) {
      const keyNames = Object.keys(keycloakProfile.attributes);
      for (const attribute of keyNames) {
        if (
          attribute !== 'uuidAccount' &&
          attribute !== 'uuidWhiteLabel' &&
          attribute !== 'nameInitials' &&
          attribute !== 'codeBank' &&
          attribute !== 'nameBank' &&
          attribute !== 'agencyBank' &&
          attribute !== 'numberAccountBank' &&
          attribute !== 'isEC' &&
          attribute !== 'uuidWl' &&
          attribute !== 'uuidAccount'
        ) {
          extras.push({ key: attribute, value: this.captureValue(keycloakProfile.attributes[attribute]) });
        }
      }
      return extras;
    }

    return extras;
  }

  private captureValue(value: any) {
    if (value && typeof value === 'object' && value.constructor === Array) {
      return value[0];
    } else {
      return value;
    }
  }

  getRoles(): string[] {
    return this.keycloakService.getUserRoles(true);
  }

  isUserInRole(role: string, resource?: string): boolean {
    return this.keycloakService.isUserInRole(role, resource);
  }

  public logoutForTimeOut() {
    let redirectUrl = document.location.protocol + '//' + document.location.hostname;

    if (document.location.hostname.includes('localhost')) {
      redirectUrl += ':' + document.location.port;
    }
    this.onLogout(redirectUrl);
  }

  onLogout(redirectUri: string) {
    this.keycloakService.logout(redirectUri);
  }

  verifyRole(): string | undefined {

    if (this.keycloakService.isUserInRole(RoleEnum.BO_ADMIN)) {
      return RoleEnum.BO_ADMIN;
    }

  };

  verifyRoleReturn(): string {

    if (this.keycloakService.isUserInRole(RoleEnum.BO_ADMIN)) {
      return RoleEnum.BO_ADMIN;
    }

    return '';
  };

  isBoAdmin(): boolean {
    return this.keycloakService.isUserInRole(RoleEnum.BO_ADMIN)
  }

}

export class UserInfoRole {
  @Injectable()
  public keycloakService: KeycloakService

  constructor(

  ) { }


  isBoAdmin(): boolean {
    return this.keycloakService.isUserInRole(RoleEnum.BO_ADMIN)
  }


}
