<div class="custom-modal modal-charged-account">

  <ng-container *ngIf="!userInfo || !form">
    <span class="indicator-progress" [style.display]="'block'">
      <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
    </span>
  </ng-container>
  <ng-container *ngIf="userInfo && form">

    <div class="modal-content">

      <div class="modal-title">
        <h1>Adicionar Conta</h1>
      </div>

      <form [formGroup]="form">

        <div class="form-group">
          <app-ui-c-input [form]="form" [formName]="'textSearch'" [name]="'Buscar conta:'"
            [type]="'text'"></app-ui-c-input>
          <button type="button" class="btn btn-primary" (click)="handleClickSearch()">

            <ng-container *ngIf="!fetchingData">
              Pesquisar
            </ng-container>

            <ng-container *ngIf="fetchingData">
              <span class="indicator-progress" [style.display]="'block'">
                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </ng-container>
          </button>
        </div>

      </form>

      <div *ngIf="accounts">

        <div class="table-container">

          <table mat-table [dataSource]="accounts">

            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-radio-group [value]="selectedAccount">
                  <mat-radio-button [value]="row" (change)="selectItem(row)" >
                  </mat-radio-button>
                </mat-radio-group>

              </td>
            </ng-container>

            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Nome </th>
              <td mat-cell *matCellDef="let element"> {{element.nameRelated}} </td>
            </ng-container>

            <ng-container matColumnDef="document">
              <th mat-header-cell *matHeaderCellDef> Documento </th>
              <td mat-cell *matCellDef="let element"> {{formatDocument(element.documentRelated)}} </td>
            </ng-container>

            <ng-container matColumnDef="accountNumber">
              <th mat-header-cell *matHeaderCellDef> Nº da conta </th>
              <td mat-cell *matCellDef="let element"> {{formatAccountBank(element.accountNumberRelated)}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" >
            </tr>

          </table>

          <div *ngIf="accounts.length === 0" class="no-data-message">
            Nenhum registro encontrado
          </div>
        </div>


      </div>

    </div>

    <div class="modal-buttons">
      <button type="button" class="btn btn-danger" (click)="handleClickCancel()">Cancelar</button>
      <button type="button" class="btn btn-primary" (click)="handleClickSubmit()" [disabled]="!selectedAccount">Confirmar</button>
    </div>
  </ng-container>
</div>