import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'modal-token',
    templateUrl: './modal-token.component.html',
    styleUrls: ['./modal-token.component.scss']
})
export class ModalTokenComponent implements OnInit {

    public tokenValue = new FormControl('');

    @Output() onSubmit = new EventEmitter();
    @Output() onResentToken = new EventEmitter();

    constructor(
        private dialogRef: MatDialogRef<ModalTokenComponent>,
    ) { }

    ngOnInit(): void {
    }

    handleClickCancel(): void {
        this.dialogRef.close();
    }
   
    handleClickResendToken(): void {
        this.onResentToken.emit();
    }

    handleClickSubmit(): void {
        this.onSubmit.emit(this.tokenValue.value);
    }
}
