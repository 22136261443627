import Swal from "sweetalert2";

export abstract class AbstractPage {
    constructor() { }

    public showConfirmationModal(text: string): Promise<boolean> {
        return Swal.fire({
            title: "Atenção",
            text: text,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "var(--kt-primary)",
            cancelButtonColor: "var(--kt-danger)",
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar",
            reverseButtons: true
        }).then((result) => {
            return result.isConfirmed;
        });
    }

    public showSuccessModal(text: string): Promise<boolean> {
        return Swal.fire({
            title: "Sucesso!",
            text: text,
            icon: "success",
            confirmButtonColor: "var(--kt-primary)",
        }).then((result) => {
            return result.isConfirmed;
        });
    }

    public showErrorModal(text: string): Promise<boolean> {
        return Swal.fire({
            title: "Erro!",
            text: text,
            icon: "error",
            confirmButtonColor: "var(--kt-primary)",
        }).then((result) => {
            return result.isConfirmed;
        });
    }

    public formatDocument(value: string): string {
        if (value == null || value == undefined || value == '') {
            return '';
        }

        value = value.replace(/(\.|\/|\-)/g, '');
        if (value.length <= 11) {
            return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
        }
        return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
    }

    public formatAccountBank(accountBank: string) {
        let account = '';
        let count = accountBank.length;
        let penultimate = count - 2;
        for (let i = 0; i < count; i++) {
            account += accountBank[i];
            if (i == penultimate) {
                account += '-';
            }
        }
        return account;
    }

}
