import {AccessAccountInterface} from './access-account.interface';

export class ExtraAttribute {
  key: string;
  value: object;
}

export class UserInfoProfile {
  uuidWhiteLabel: string;
  uuidAccount: string;
  nameComplete: string;
  nameFirst: string;
  nameInitials: string;
  documentDefault?: string;
  email: string;
  codeBank: string;
  nameBank: string;
  agencyBank: string;
  numberAccountBank: string;
  isEC: boolean;
  document: string;
  accountType: string;
  extraAttributes?: ExtraAttribute[];
  listAccounts?: Array<AccessAccount> = [];
  rolesMenu: Array<string>;
}

export interface AccessAccount extends AccessAccountInterface {

}
