import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../_base/_auth/api.service';

const SPE_N_ACCOUNTS = '/spe-n-accounts';

@Injectable(
  { providedIn: 'root' }
)
export class SpeNAccountsProxy {

  constructor(private api: ApiService) {
  }

  changeAccount(uuidUserAccount: string): Observable<any> {
    const uri = `${SPE_N_ACCOUNTS}/v1/access/change`;
    // @ts-ignore
    return this.api.put(uri, null, null, [{name: 'X-User-Account-ID', value: uuidUserAccount }]);
  }

}
