import { Component, Input, OnInit } from '@angular/core';
import { TableData } from './table.dto';

@Component({
  selector: 'ui-c-table',
  templateUrl: './ui-c-table.component.html',
  styleUrls: ['./ui-c-table.component.scss']
})
export class UiCTableComponent implements OnInit {

  @Input() tableData: TableData;

  ngOnInit(): void {}

}
