import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { PageHeaderComponent } from './components/page-header/page-header.component';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from "@angular/forms";

import { SidebarComponent } from './components/sidebar/sidebar.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { BreadCrumbComponent } from './components/bread-crumb/bread-crumb.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { InterceptService } from 'src/app/core/_base/_auth/intercept/intercept.service';


@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    BreadCrumbComponent,
    PageHeaderComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    ReactiveFormsModule,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    InlineSVGModule
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    PageHeaderComponent,
    SidebarComponent,
    BreadCrumbComponent,
    RouterModule,
    ReactiveFormsModule,

  ],
  providers:[
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
  ]
})
export class SharedModule { }
